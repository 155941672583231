jQuery(document).ready(function($) {
    
	$('.js-product-carousel').slick({
		slidesToShow: 1,
		arrows: false,
		dots: false
	});

	// Product carousel buttons
	$('.product-carousel-buttons button').click(function() {
		var slideNumber = $(this).data('slide');
		$('.product-carousel-buttons button.active').removeClass('active').addClass('inactive');
		$(this).addClass('active').removeClass('inactive');
		$('.js-product-carousel').slick('slickGoTo', slideNumber);
	});
 
});