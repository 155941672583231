jQuery(document).ready(function($) {
    
    if($('#timeline').length) {
	    if($(window).width() >= 992) {
			const container = document.getElementById("timeline");
			container.addEventListener("wheel", function (e) {
			    if (e.deltaY > 0) {
			      	container.scrollLeft += 100;
			      	e.preventDefault();
			    } else {
			      	container.scrollLeft -= 100;
			      	e.preventDefault();
			    }
			});    	
	    }    	
    }
 
});