jQuery(document).ready(function($) {
    
	$('.js-logo-carousel').slick({
		slidesToShow: 4,
		arrows: true,
		dots: false,
		infinite: true,
		responsive: [{
	      	breakpoint: 1024,
	      	settings: {
	        	slidesToShow: 3
	      	}
	    },
	    {
	      	breakpoint: 767,
	      	settings: {
	        	slidesToShow: 2
	      	}
	    },
	    {
	      	breakpoint: 500,
	      	settings: {
	        	slidesToShow: 1
	      	}
	    }]
	});
 
});
