jQuery(document).ready(function($) {
    
	$('.header__nav-button').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$('#header__nav').stop().toggleClass('active').slideToggle();
	});

	$('.menu-item-has-children > a').click(function(e) {
		if($(window).width() < 992) {
			e.preventDefault();
			var $parent = $(this).parent('.menu-item-has-children');
			$parent.toggleClass('active');
		}
	});
 
});
