jQuery(document).ready(function($) {
    
	$('.js-panel-carousel').slick({
		slidesToShow: 1,
		arrows: true,
		dots: true,
		infinite: true,
		centerMode: true,
		variableWidth: true,
		responsive: [{
	      	breakpoint: 1024,
	      	settings: {
	        	centerMode: false,
	        	variableWidth: false
	      	}
	    }]
	});
 
});